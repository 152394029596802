import { render, staticRenderFns } from "./NewDashboardShopFilters.vue?vue&type=template&id=16106361&scoped=true&"
import script from "./NewDashboardShopFilters.vue?vue&type=script&lang=js&"
export * from "./NewDashboardShopFilters.vue?vue&type=script&lang=js&"
import style0 from "./NewDashboardShopFilters.vue?vue&type=style&index=0&id=16106361&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16106361",
  null
  
)

export default component.exports