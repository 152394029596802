export default Object.freeze({
  boxes: 'Заказов',
  money: 'Заказов на сумму',
  shop: 'Подключено',
  percent: 'Процент выкупа',
  adv: 'Расходы на РК',
  loyalty: 'Расходы на лояльность',
  drr: 'ДРР',
  pl: 'Расходы на ПЛ'
})
