<template>
  <x-card class="tile-indicator">
    <div
      v-if="loading"
      class="dashboard__loader"
    >
      <loader />
    </div>
    <dl
      v-else
      class="tile-indicator__indication"
    >
      <dt class="tile-indicator__header">
        <svg-icon
          :name="indicatorIconName"
          width="40"
          height="40"
        />
        <div
          v-if="indicator.dimension !== 'shops'"
          class="tile-indicator__header-badge"
        >
          за период
        </div>
      </dt>
      <dt class="tile-indicator__title">
        {{ indicatorName }}
      </dt>

      <dd class="tile-indicator__content">
        {{ formattedTotal }}
      </dd>
      <ul class="tile-indicator__list">
        <li
          v-for="shop in shopsList"
          :key="shop.id"
          :class="indicator.dimension === 'shops' ? 'tile-indicator__list-anchor' : 'tile-indicator__list-content'"
        >
          <span class="tile-indicator__list-shop">
            <x-icon
              :name="shop.marketplace_code"
              size="s"
              class="mr-2"
            />
            {{ shop.label }}
          </span>
          <a
            v-if="indicator.dimension === 'shops'"
            :href="`/new_dashboards/${shop.marketplace_code}/${shop.id}`"
            class="tile-indicator__list-anchor-value"
          >
            К сводке
            <x-icon
              name="chevron-right"
              size="s"
            />
          </a>
          <span
            v-else
            class="tile-indicator__list-value"
          >
            {{ formatedSingle(shop[indicator.indicator_type]) }}
          </span>
        </li>
      </ul>
    </dl>
  </x-card>
</template>

<script>
import summary from '@/constants/dashboard-new-summary'
import formatPrice from '@/utils/price-formatter'
import Loader from '@/components/Common/Loader.vue'

const dimensions = {
  currency: '',
  rubles: '₽',
  counter: 'шт',
  percent: '%',
  shops: 'магазина'
}

export default {
  components: {
    Loader
  },

  props: {
    indicator: {
      type: Object,
      required: true
    },
    shops: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      shopsList: []
    }
  },

  watch: {
    shops (value) {
      this.shopsList = value
    }
  },

  computed: {
    indicatorName () {
      return summary[this.indicator.indicator_type]
    },

    indicatorIconName () {
      return this.indicator.indicator_type
    },

    formattedTotal () {
      const digits = this.indicator.indicator_type === 'avg_check' ? 2 : 0

      return `
  ${formatPrice(this.indicator.indicator_value, { maximumFractionDigits: digits })}
  ${this.indicator.dimension !== 'currency' ? dimensions[this.indicator.dimension] : ''}
`
    }
  },

  mounted () {
    this.shopsList = this.shops
  },

  methods: {
    formatedSingle (indicator) {
      return formatPrice(indicator, { maximumFractionDigits: 0 }) + ` ${this.indicator.dimension !== 'currency' ? dimensions[this.indicator.dimension] : ''}`
    }
  }
}
</script>

<style lang="stylus" scoped>

  .tile-indicator
    height 100%
    padding-bottom 8px

    @media (max-width 768px)
      padding-right 15px
      padding-left 15px

    &__indication
      display flex
      flex-direction column
      justify-content space-between
      height 100%

    &__title
      margin-bottom 5px
      font-size 16px
      font-weight 600
      color #3C3C47

    &__content
      overflow hidden
      font-weight $font-weights.bold
      font-size 32px
      color #3C3C47
      margin-bottom 20px
      white-space nowrap
      text-overflow ellipsis

    &__header
      display flex
      justify-content space-between
      align-items center
      padding 8px 0
      margin-bottom 10px

    &__header-badge
      color #A3A3AC
      border 1px solid #A3A3AC
      border-radius 90px
      font-size 12px
      padding 2px 8px

    &__list
      padding 0

    &__list-anchor
      display flex
      justify-content space-between
      border-bottom 1px solid #A3A3AC
      padding 8px 0

      &:last-child
        border none

    &__list-content
      align-items center
      display flex
      padding 8px 0

      &::before
        content ''
        border-bottom 1px dashed #A3A3AC
        flex-grow 1
        align-self flex-end
        order 2
        margin 0 2px

    &__list-value
      order 3
      display flex
      align-items center
      font-weight bold

    &__list-anchor-value
      order 3
      display flex
      align-items center

    &__list-shop
      display flex
      align-items center
</style>
