<template>
  <div class="summary">
    <ul class="summary__list">
      <swiper
        v-if="$currentDevice.isMobile"
        class="swiper"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="(item, index) in indicators"
          :key="index"
          class="summary__item"
        >
          <tile
            :indicator="item"
            :loading="loading"
          />
        </swiper-slide>

        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>

      <template v-else>
        <li
          v-for="(item, index) in indicators"
          :key="index"
          class="summary__item"
        >
          <tile
            :indicator="item"
            :shops="shops"
            :loading="loading"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Tile from './NewTile.vue'

export default {

  components: {
    Tile,
    Swiper,
    SwiperSlide
  },

  props: {
    indicators: {
      type: Array,
      required: true
    },
    shops: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      swiperOption: {
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .summary
    height 100%

    @media (max-width 768px)
      margin-right -15px
      margin-left -15px
      overflow hidden

    &__item
      flex-shrink 0
      flex-grow 1
      min-width 160px

    &__list
      height 100%
      display flex
      flex-wrap wrap
      justify-content flex-start
      align-items stretch
      gap 30px
      margin 0
      padding 0
      list-style none

      @media (max-width 768px)
        max-width 90vw

  .swiper-container
    overflow unset
    position unset
    margin 30px 0 0 10px
    min-height 200px
    max-width 14.41%
    min-width 160px

  .swiper-pagination
    bottom -10px
</style>
